<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>
    <el-backtop target=".el-main"></el-backtop>
    <div class="search">
      <div style="display: flex; flex-wrap: wrap">
        <el-select
          v-model="rewardType"
          placeholder="请选择类别"
          @change="rewardTypeList"
        >
          <el-option label="全部" value="-1"></el-option>
          <el-option label="司机" value="0"></el-option>
          <el-option label="乘客" value="1"></el-option>
          <el-option label="分享者" value="2"></el-option>
          <el-option label="趣宝盒id" value="3"></el-option>
          <el-option label="nft线路" value="4"></el-option>
        </el-select>
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 220px; margin-right: 10px"
          v-if="internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入手机号" v-model="phone"></el-input>
        <el-input
          placeholder="请输入要查询的钱包地址"
          style="width: 280px"
          v-model="walletUrl"
        ></el-input>
        <el-date-picker
          v-model="date"
          type="date"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          :clearable="false"
        >
        </el-date-picker>

        <div>
          <el-button type="primary" @click="searchList">搜索</el-button>
          <el-button type="info" @click="reset">重置</el-button>
          <el-button plain @click="toggleSelection(tableData)">全 选</el-button>
          <el-button @click="handleBatchBtn()">批 量</el-button>
          <el-button @click="exportToExcel">导 出</el-button>
        </div>
      </div>

      <div style="color: red; line-height: 28px">
        {{ date }} 待发放奖励汇总：{{ AllCount }}
      </div>
    </div>
    <div class="table_con" style="height: calc(100vh - 200px)">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        border
        @row-click="handleRowClick"
        @selection-change="handleSelectionChange"
        id="mytable"
      >
        <el-table-column type="selection" width="40" align="center">
        </el-table-column>
        <el-table-column type="index" label="序号" width="80" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="180">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="travelId" label="行程ID" width="180">
        </el-table-column>
        <el-table-column prop="type" label="类型" width="180">
          <template slot-scope="scope">
            {{
              scope.row.type == 0
                ? "司机"
                : scope.row.type == 1
                ? "乘客"
                : scope.row.type == 2
                ? "分享者"
                : scope.row.type == 3
                ? "趣宝盒id"
                : scope.row.type == 4
                ? "nft线路"
                : ""
            }}
          </template>
        </el-table-column>
        <el-table-column prop="startTime" label="发车时间" width="200">
        </el-table-column>
        <el-table-column prop="startImg" label="发车图片" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.startImg"
              :preview-src-list="[scope.row.startImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="endTime" sortable label="结束时间" width="180">
        </el-table-column>
        <el-table-column prop="endImg" label="行程结束图片" width="180">
          <template slot-scope="scope">
            <el-image
              style="width: 100%; height: 100%"
              :src="scope.row.endImg"
              :preview-src-list="[scope.row.endImg]"
            >
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="startPlace" label="行程起点" width="200">
        </el-table-column>
        <el-table-column prop="endPlace" label="行程终点" width="200">
        </el-table-column>
        <el-table-column prop="consumeTime" label="行程耗时" width="150">
        </el-table-column>
        <el-table-column prop="distance" label="行驶距离" width="200">
        </el-table-column>
        <el-table-column prop="travelStatus" label="行程状态">
        </el-table-column>
        <el-table-column prop="cple" label="待奖励CPLE" width="200">
        </el-table-column>
        <el-table-column prop="pledge" label="质押量" width="130">
        </el-table-column>
        <el-table-column prop="cpleaddress" label="钱包地址" width="230">
        </el-table-column>
        <el-table-column
          prop="rewardStatus"
          label="奖励状态"
          fixed="right"
          align="center"
          width="100"
        >
          <template slot-scope="scope">
            <div class="operation" v-if="scope.row.rewardStatus == '2'">
              已奖励
            </div>
            <div class="operation" v-else-if="scope.row.rewardStatus == '1'">
              <el-button
                @click="handleClick(scope.row, 1)"
                type="text"
                size="small"
                >同意奖励</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="handleClick(scope.row, 2)"
                >拒绝奖励</el-button
              >
            </div>
            <div class="operation" v-else-if="scope.row.rewardStatus == '3'">
              已拒绝
            </div>
          </template>
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import * as xlsx from "xlsx";
import { hideLoading, showLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";
import { format_time_date } from "@/util/funBusiness";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  inject: ["reload"],
  data() {
    return {
      multipleSelection: [],
      tableData: [],

      valueArea: "",
      options: [],
      rewardType: "",
      phone: "",
      walletUrl: "",
      date: "",

      AllCount: "",

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "CPLE每日奖励下发",
        },
        {
          name: "待发放奖励",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 200, //要传过去的数据 每页多少条数据
      pageSizes: [200, 500, 1000, 5000, 10000, 20000, 50000, 100000],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");
    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getRewardList,
      method: "POST",
      params: JSON.stringify({
        cpleAddress: "",
        field1: "start_time",
        field2: "",
        nonce: "",
        order1: "0",
        order2: "",
        pageNum: 0,
        pageSize: 200,
        phone: "",
        phoneArea: this.phoneAreaROLE,
        startTime: "",
        rewardStatus: "1", // 1待发放，2同意，3拒绝
        sign: "",
        token: "",
        type: "-1",
      }),
      resFunc: (res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.list;
          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;

          res.data.data.list.map(
            (item) => (item.endTime = format_time_date(item.endTime))
          );
          hideLoading();
        }

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
        hideLoading();
      },
    };
    request(opt);

    const optCount = {
      url: reqUrl.getTravelRewardsSum,
      method: "POST",
      params: JSON.stringify({ endTime: this.date }),
      resFunc: (res) => {
        this.AllCount = res.data.data;

        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
        }
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        console.log(err, "err1");
      },
    };
    request(optCount);
  },

  methods: {
    searchList() {
      if (
        this.phone ||
        this.walletUrl ||
        this.date ||
        this.rewardType ||
        this.valueArea
      ) {
        showLoading();
        const opt = {
          url: reqUrl.getRewardList,
          method: "POST",
          params: JSON.stringify({
            cpleAddress: this.walletUrl,
            field1: "start_time",
            field2: "",
            nonce: "",
            order1: "0",
            order2: "",
            pageNum: 0,
            pageSize: 200,
            phone: this.phone,
            phoneArea: this.valueArea || this.phoneAreaROLE,
            startTime: this.date,
            rewardStatus: "1", // 1待发放，2同意，3拒绝
            sign: "",
            token: "",
            type: this.rewardType ? this.rewardType : "-1",
          }),
          resFunc: (res) => {
            if (res.data.code == 0) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
              res.data.data.list.map(
                (item) => (item.endTime = format_time_date(item.endTime))
              );
              hideLoading();
            }

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
            hideLoading();
          },
        };
        request(opt);

        const optCount = {
          url: reqUrl.getTravelRewardsSum,
          method: "POST",
          params: JSON.stringify({ endTime: this.date }),
          resFunc: (res) => {
            this.AllCount = res.data.data;

            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
            }
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            console.log(err, "err1");
          },
        };
        request(optCount);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    // 类别
    rewardTypeList() {
      showLoading();
      const opt = {
        url: reqUrl.getRewardList,
        method: "POST",
        params: JSON.stringify({
          cpleAddress: this.walletUrl,
          field1: "start_time",
          field2: "",
          nonce: "",
          order1: "0",
          order2: "",
          pageNum: 0,
          pageSize: 200,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime: this.date,
          rewardStatus: "1", // 1待发放，2同意，3拒绝
          sign: "",
          token: "",
          type: this.rewardType ? this.rewardType : "-1",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            res.data.data.list.map(
              (item) => (item.endTime = format_time_date(item.endTime))
            );
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    handleRowClick(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },

    // 全选
    toggleSelection(rows) {
      if (rows.length > 0) {
        let rowIdsSelected = [];
        rows.map((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
          rowIdsSelected.push(row.id);
        });

        this.$confirm("您确定要执行此操作吗?", "提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "同意奖励",
          cancelButtonText: "拒绝奖励",
          type: "warning",
        })
          .then(() => {
            this.$prompt("请输入Txid", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /^.+$/,
              inputValidator: (value) => {
                if (!value.trim()) {
                  return "内容不能为空";
                }
                if (value.length > 70) {
                  return "内容超长";
                }
                return true;
              },
            })
              .then(({ value }) => {
                const opt = {
                  url: reqUrl.ravelRewardExaimne,
                  method: "POST",
                  params: JSON.stringify({
                    auditor: localStorage.getItem("userName"),
                    ids: rowIdsSelected,
                    result: "2",
                    txid: value,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });

                this.$refs.multipleTable.clearSelection();
              });
          })
          .catch((action) => {
            if (action == "cancel") {
              this.$confirm("您确定要执行此操作吗?", "提示", {
                distinguishCancelAndClose: true,
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  const opt = {
                    url: reqUrl.ravelRewardExaimne,
                    method: "POST",
                    params: JSON.stringify({
                      auditor: localStorage.getItem("userName"),
                      ids: rowIdsSelected,
                      result: "3",
                      txid: "",
                    }),
                    resFunc: (res) => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.$message({
                          type: "success",
                          message: "操作成功!",
                        });
                        this.refresh();
                      } else if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      console.log(err, "err1");
                    },
                  };
                  request(opt);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消操作",
                  });
                  this.$refs.multipleTable.clearSelection();
                });
            } else if (action == "close") {
              this.$refs.multipleTable.clearSelection();
            }
          });
      } else {
        this.$notify.error({
          title: "错误",
          message: "暂无可选项",
        });
      }
    },

    // 批量
    handleBatchBtn() {
      if (this.multipleSelection.length > 0) {
        let batchIds = [];
        this.multipleSelection.map((item) => {
          batchIds.push(item.id);
        });
        this.$confirm("您确定要执行此操作吗?", "提示", {
          distinguishCancelAndClose: true,
          confirmButtonText: "同意奖励",
          cancelButtonText: "拒绝奖励",
          type: "warning",
        })
          .then(() => {
            this.$prompt("请输入Txid", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /^.+$/,
              inputValidator: (value) => {
                if (!value.trim()) {
                  return "内容不能为空";
                }
                if (value.length > 70) {
                  return "内容超长";
                }
                return true;
              },
            })
              .then(({ value }) => {
                const opt = {
                  url: reqUrl.ravelRewardExaimne,
                  method: "POST",
                  params: JSON.stringify({
                    auditor: localStorage.getItem("userName"),
                    ids: batchIds,
                    result: "2",
                    txid: value,
                  }),
                  resFunc: (res) => {
                    console.log(res.data);
                    if (res.data.code == 0) {
                      this.$message({
                        type: "success",
                        message: "操作成功!",
                      });
                      this.refresh();
                    } else if (res.data.code != 0) {
                      this.$notify.error({
                        title: "错误",
                        message: res.data.message,
                      });
                      hideLoading();
                    }
                  },
                  errFunc: (err) => {
                    this.$notify.error({
                      title: "错误",
                      message: err,
                    });
                    console.log(err, "err1");
                  },
                };
                request(opt);
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消操作",
                });

                this.$refs.multipleTable.clearSelection();
              });
          })
          .catch((action) => {
            if (action == "cancel") {
              this.$confirm("您确定要执行此操作吗?", "提示", {
                distinguishCancelAndClose: true,
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  const opt = {
                    url: reqUrl.ravelRewardExaimne,
                    method: "POST",
                    params: JSON.stringify({
                      auditor: localStorage.getItem("userName"),
                      ids: batchIds,
                      result: "3",
                      txid: "",
                    }),
                    resFunc: (res) => {
                      console.log(res.data);
                      if (res.data.code == 0) {
                        this.$message({
                          type: "success",
                          message: "操作成功!",
                        });
                        this.refresh();
                      } else if (res.data.code != 0) {
                        this.$notify.error({
                          title: "错误",
                          message: res.data.message,
                        });
                        hideLoading();
                      }
                    },
                    errFunc: (err) => {
                      this.$notify.error({
                        title: "错误",
                        message: err,
                      });
                      console.log(err, "err1");
                    },
                  };
                  request(opt);
                })
                .catch(() => {
                  this.$message({
                    type: "info",
                    message: "已取消操作",
                  });
                  this.$refs.multipleTable.clearSelection();
                });
            } else if (action == "close") {
              this.$refs.multipleTable.clearSelection();
            }
          });
      } else {
        this.$notify.error({
          title: "错误",
          message: "暂无可选项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getRewardList,
        method: "POST",
        params: JSON.stringify({
          cpleAddress: this.walletUrl,
          field1: "start_time",
          field2: "",
          nonce: "",
          order1: "0",
          order2: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          startTime: this.date,
          rewardStatus: "1", // 1待发放，2同意，3拒绝
          sign: "",
          token: "",
          type: this.rewardType ? this.rewardType : "-1",
        }),
        resFunc: (res) => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            res.data.data.list.map(
              (item) => (item.endTime = format_time_date(item.endTime))
            );
            hideLoading();
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },

    handleClick(row, index) {
      let idsValue = [];
      idsValue.push(row.id);

      if (index == 1) {
        this.$prompt("请输入Txid", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^.+$/,
          inputValidator: (value) => {
            if (!value.trim()) {
              return "内容不能为空";
            }
            if (value.length > 70) {
              return "内容超长";
            }
            return true;
          },
        })
          .then(({ value }) => {
            console.log(value);
            const opt = {
              url: reqUrl.ravelRewardExaimne,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                ids: idsValue,
                result: "2",
                txid: value,
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.refresh();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });

            this.$refs.multipleTable.clearSelection();
          });
      } else if (index == 2) {
        this.$confirm("您确定要执行此操作吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            const opt = {
              url: reqUrl.ravelRewardExaimne,
              method: "POST",
              params: JSON.stringify({
                auditor: localStorage.getItem("userName"),
                ids: idsValue,
                result: "3",
                txid: "",
              }),
              resFunc: (res) => {
                console.log(res.data);
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "操作成功!",
                  });
                  this.refresh();
                } else if (res.data.code != 0) {
                  this.$notify.error({
                    title: "错误",
                    message: res.data.message,
                  });
                  hideLoading();
                }
              },
              errFunc: (err) => {
                this.$notify.error({
                  title: "错误",
                  message: err,
                });
                console.log(err, "err1");
              },
            };
            request(opt);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消操作",
            });

            this.$refs.multipleTable.clearSelection();
          });
      }
    },

    reset() {
      if (
        this.rewardType ||
        this.phone ||
        this.walletUrl ||
        this.date ||
        this.valueArea
      ) {
        this.phone = "";
        this.walletUrl = "";
        this.date = "";
        this.rewardType = "";
        this.valueArea = "";

        this.refresh();
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可重置项",
        });
      }
    },

    // 刷新
    refresh() {
      this.reload();
    },

    exportToExcel() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          message: "请先选择你要导出的数据哦",
          type: "warning",
        });
        return;
      }

      const arr = this.multipleSelection.map((item, index) => {
        return {
          序号: index + 1,
          手机号: item.phone,
          行程ID: item.travelId,
          发车时间: item.startTime,
          行程起点: item.startPlace,
          行程终点: item.endPlace,
          行程耗时: item.consumeTime,
          行程状态: item.travelStatus,
          行驶距离: item.distance,
          待奖励CPLE: item.cple,
          质押量: item.pledge,
          钱包地址: item.cpleaddress,
        };
      });
      const sheet = xlsx.utils.json_to_sheet(arr);
      const book = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(book, sheet, "sheet1");
      xlsx.writeFile(book, "行程奖励（历史）.xlsx");
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .el-input {
    width: 200px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .el-select {
    margin-right: 10px;
  }
}

.pagination {
  margin-bottom: 10px;
}

.operation {
  display: flex;
  flex-direction: column;
  color: #b4b4b4;
  font-weight: bold;

  .el-button + .el-button {
    margin-left: 0;
    margin-top: 2px;
  }

  .el-button {
    border: 1px #ccc solid;
    color: #666;
    background: #d9d9d9;
  }
}
</style>
